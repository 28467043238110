import { createStore } from 'vuex'
import flow from 'lodash/fp/flow'
import shuffle from 'lodash/fp/shuffle'
import filter from 'lodash/fp/filter'
import head from 'lodash/fp/head'

import state from './state'

const language = localStorage.getItem('language') || state.language

// eslint-disable-next-line
export default createStore({
  strict: true,
  state: {
    ...state,
    language,
  },
  mutations: {
    menu(state, isMenuOpen) {
      Object.assign(state, { isMenuOpen })
    },
    language(state, language) {
      Object.assign(state, { language })
    },
    featuredProject(state, featuredProject) {
      Object.assign(state, { featuredProject })
    },
  },
  actions: {},
  getters: {
    cloudinary: () => (id, width, height) => {
      const quality = 70
      const dpr = window.devicePixelRatio || 1
      const baseUrl = 'https://res.cloudinary.com/plantasdafonte/image/upload/'
      return `${baseUrl}c_fill,q_${quality},h_${height},w_${width},dpr_${dpr}/${id}.jpg`
    },
    theme: () => (route) => {
      const themes = {
        contact: 'dark',
        about: 'green',
      }
      return themes[route] || 'default'
    },
    featuredProject: (state) => {
      const featuredGallery = flow(
        filter('featured'),
        shuffle,
        head
      )(state.galleries)
      const { src } = flow(
        shuffle,
        filter('featured'),
        head
      )(featuredGallery.images)
      return {
        name: featuredGallery.name,
        src,
        url: `/gallery/${featuredGallery.id}`,
      }
    },
  },
})
