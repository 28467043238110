import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'

import App from './App.vue'
import router from './router'
import store from './store'
import messages from './messages'
import './registerServiceWorker'

const i18n = createI18n({
  locale: store.state.language,
  messages,
})
createApp(App).use(store).use(router).use(i18n).mount('#app')
