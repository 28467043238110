import { createRouter, createWebHistory } from 'vue-router'

import HomePage from './views/HomePage'
import GalleryPage from './views/GalleryPage'
import AboutPage from './views/AboutPage'
import ContactPage from './views/ContactPage'
import PhotoSwipe from './views/PhotoSwipe'
import { trackPage } from './ga'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
  },
  {
    path: '/gallery/:id',
    name: 'gallery',
    component: GalleryPage,
  },
  {
    path: '/gallery/:gallery/:image',
    name: 'gallery-viewer',
    component: PhotoSwipe,
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactPage,
  },
  {
    path: '/about',
    name: 'about',
    component: AboutPage,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
})

router.afterEach((to) => {
  trackPage(to)
})

export default router
