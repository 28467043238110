export default {
  en: {
    home: {
      title_p1: 'We build green spaces.',
      title_p2: 'We create life.',
    },
    menu: {
      home: 'Home',
      quinta_freiria: 'Quinta da Freiria',
      gardens: 'Gardens',
      nurseries: 'Nurseries',
      contact: 'Contact',
      about: 'About',
    },
    gallery: {
      gardens: 'Gardens',
      nurseries: 'Nurseries',
      freiria: 'Quinta da Freiria (Headquarters)',
    },
    about: {
      company: {
        title: 'Company',
        text: {
          p1: 'We are a professional company with 30 years experience.The quality of our services can be seen in the inumerous work done.',
          p2: 'Visite the garden in our headquarters in Quinta da Freiria.',
          p3: 'Talk to us about the garden you desire. We have countless solutions to show you.',
        },
      },
      services: {
        title: 'Services',
        general: {
          title: 'Green spaces construction',
          list: [
            'Cleaning, preparation and fertilization of the soil',
            'Land movement',
            'Modelling and levelling of the ground',
            'Drainage',
            'Waterproofing of tanks and cisterns',
            'Bombing',
            'Automatic irrigation',
            'Illumination',
            'Construction of cisterns and swimming pools',
            'Construction of lakes, fountains, waterfalls and bridges',
            'Plantation of trees, shrubs and herbaceous',
            'Lawn',
          ],
        },
        events: {
          title: 'Events decoration',
          list: [
            'Decoration and renting of plants for interior and exterior spaces',
          ],
        },
        maintenance: {
          title: 'Maintenance',
          list: ['Interior plants maintenance', 'Gardens maintenance'],
        },
      },
    },
    contact: {
      details: [
        {
          title: 'quinta da freiria',
          texts: ['4805-291 PONTE GMR', 'Guimarães'],
        },
        {
          title: 'working hours',
          texts: ['9:00 am - 1:00 pm', '2:30 pm  - 6:30 pm'],
        },
        {
          title: 'contacts',
          texts: [
            'Tel 253.574.658',
            'Fax 253.575.157',
            'E-mail info@plantasdafonte.pt',
          ],
        },
        {
          title: 'location',
          texts: [
            'Ponte - Guimarães',
            'National Road 101',
            'Guimarães - Braga',
            'at 6 Km from Guimarães',
            'at 1 Km from Caldas das Taipas',
            '41°28\'53.383"N, 8°20\'7.59"W',
          ],
        },
      ],
    },
  },
  es: {
    home: {
      title_p1: 'Construimos espacios verdes.',
      title_p2: 'Creamos vida.',
    },
    menu: {
      home: 'Inicio',
      quinta_freiria: 'Quinta da Freiria',
      gardens: 'Jardines',
      nurseries: 'Viveros',
      contact: 'Contacto',
      about: 'Empresa',
    },
    gallery: {
      gardens: 'Jardines',
      nurseries: 'Viveros',
      freiria: 'Quinta da Freiria -&nbsp;Sede',
    },
    about: {
      company: {
        title: 'Empresa',
        text: {
          p1: 'Somos profesionales con una experiencia de 30 años. La calidad de nuestros servicios se manifiesta en el trabajo hecho hasta ahora.',
          p2: 'Visite nuestro jardín modelo en nuestra sede en Quinta da Freiria.',
          p3: 'Háblenos sobre el jardín que usted desea. Tenemos toda la clase de soluciones.',
        },
      },
      services: {
        title: 'Servicios',
        general: {
          title: 'Construcción de espacios verdes',
          list: [
            'Limpieza, preparación y fertilización del suelo',
            'Movimiento de tierra',
            'Modelación y nivelación de la tierra',
            'Drenaje',
            'Impermeabilización de tanques y de cisternas',
            'Bombeo',
            'Riego automático',
            'Iluminación',
            'Construcción de cisternas y piscinas',
            'Construcción de lagos, fuentes, cascadas y puentes',
            'Plantación de árboles, arbustos y herbáceos',
            'Céspedes',
          ],
        },
        events: {
          title: 'Decoración de Eventos',
          list: [
            'Decoración y alquiler de plantas para espacios interiores y exteriores',
          ],
        },
        maintenance: {
          title: 'Mantenimiento',
          list: [
            'Mantenimiento de plantas de interior',
            'Mantenimiento de jardines',
          ],
        },
      },
    },
    contact: {
      details: [
        {
          title: 'quinta da freiria',
          texts: ['4805-291 PONTE GMR', 'Guimarães'],
        },
        {
          title: 'horario comercial',
          texts: ['9:00 - 13:00', '14:30 - 18:30'],
        },
        {
          title: 'contactos',
          texts: [
            'Tel 253.574.658',
            'Fax 253.575.157',
            'E-mail info@plantasdafonte.pt',
          ],
        },
        {
          title: 'localización',
          texts: [
            'Ponte - Guimarães',
            'Carretera Nacional 101',
            'Guimarães - Braga',
            'a 6 Km de Guimarães',
            'a 1 Km de Caldas das Taipas',
            '41°28\'53.383"N, 8°20\'7.59"W',
          ],
        },
      ],
    },
  },
  fr: {
    home: {
      title_p1: 'Nous construisons des espaces verts.',
      title_p2: 'Nous créons la vie.',
    },
    menu: {
      home: 'Début',
      quinta_freiria: 'Quinta da Freiria',
      gardens: 'Jardins',
      nurseries: 'Pépinières',
      contact: 'Contact',
      about: 'Société',
    },
    gallery: {
      gardens: 'Jardins',
      nurseries: 'Pépinières',
      freiria: 'Quinta da Freiria (Siège)',
    },
    about: {
      company: {
        title: 'Société',
        text: {
          p1: "Nous sommes professionnels avec 30 ans d'expérience. La qualité de nos services se trouve claire dans les innombrables travaux que nos avons déjà effectué.",
          p2: 'Vennez visiter le jardin modèle existant à notre siège dans Quinta da Freiria.',
          p3: 'Parlez avec nous sur le jardin de vos réves. Nous avons tout le type de solutions pour vous présenter.',
        },
      },
      services: {
        title: 'Services',
        general: {
          title: "Construction d'espaces verts",
          list: [
            'Nettoyage, préparation et fertilisation du terrain',
            'Mouvement de terres',
            'Modélisation et nivellement de la terre.',
            'Drainage',
            'Imperméabilisation de réservoirs et citernes',
            'Pompage',
            'Arrosage automatique',
            'Illumination',
            'Construction de citernes et piscines',
            "Construction de bassins, jets d'eau, chutes d'eau et ponts",
            "Plantation d'Arbres, d'Arbustes et de Herbacés",
            'Pelouses',
          ],
        },
        events: {
          title: 'Décorations pour événements',
          list: [
            'Decoration et location de plantes pour intérieur et extérieur',
          ],
        },
        maintenance: {
          title: 'Entretien',
          list: ["Entretien de plantes d'intérieur", 'Entretien de jardins'],
        },
      },
    },
    contact: {
      details: [
        {
          title: 'quinta da freiria',
          texts: ['4805-291 PONTE GMR', 'Guimarães'],
        },
        {
          title: 'horaire',
          texts: ['9:00H - 13:00H', '14:30H - 18:30H'],
        },
        {
          title: 'contacts',
          texts: [
            'Tel 253.574.658',
            'Fax 253.575.157',
            'E-mail info@plantasdafonte.pt',
          ],
        },
        {
          title: 'localisation',
          texts: [
            'Ponte - Guimarães',
            'Route Nationale 101',
            'Guimarães - Braga',
            'à 6Km de Guimarães',
            'à 1 Km de Caldas das Taipas',
            '41°28\'53.383"N, 8°20\'7.59"W',
          ],
        },
      ],
    },
  },
  pt: {
    home: {
      title_p1: 'Construimos espaços verdes.',
      title_p2: 'Criamos vida.',
    },
    menu: {
      home: 'Início',
      quinta_freiria: 'Quinta da Freiria',
      gardens: 'Jardins',
      nurseries: 'Viveiros',
      contact: 'Contacto',
      about: 'Empresa',
    },
    gallery: {
      gardens: 'Jardins',
      nurseries: 'Viveiros',
      freiria: 'Quinta da Freiria —&nbsp;Sede',
    },
    about: {
      company: {
        title: 'Empresa',
        text: {
          p1: 'Somos profissionais com 30 anos de experiência. A qualidade dos nossos serviços encontra-se patente nas inúmeras obras que já construímos.',
          p2: 'Visite o jardim modelo existente na nossa sede na Quinta da Freiria.',
          p3: 'Fale connosco sobre o jardim que imagina e pretende. Temos todo o tipo de soluções para lhe apresentar.',
        },
      },
      services: {
        title: 'Serviços',
        general: {
          title: 'Construção de espaços verdes',
          list: [
            'Limpeza, preparação e fertilização do terreno',
            'Movimento de terras',
            'Modelação e nivelamento do solo',
            'Drenagem',
            'Impermeabilização de tanques e cisternas',
            'Bombagem',
            'Rega automática',
            'Iluminação',
            'Construção de cisternas e piscinas biológicas',
            'Construção de Lagos, repuxos, quedas de água e pontes',
            'Plantação de Árvores, Arbustos e Herbáceas',
            'Relvados',
          ],
        },
        events: {
          title: 'Decorações para eventos',
          list: [
            'Decoração e aluguer de plantas para espaços interiores e exteriores',
          ],
        },
        maintenance: {
          title: 'Manutenção',
          list: ['Manutenção de plantas de interior', 'Manutenção de jardins'],
        },
      },
    },
    contact: {
      details: [
        {
          title: 'quinta da freiria',
          texts: ['4805-291 PONTE GMR', 'Guimarães'],
        },
        {
          title: 'horário de atendimento',
          texts: ['9:00 - 13:00', '14:30 - 18:30'],
        },
        {
          title: 'contactos',
          texts: [
            'Tel 253.574.658',
            'Fax 253.575.157',
            'E-mail info@plantasdafonte.pt',
          ],
        },
        {
          title: 'localização',
          texts: [
            'Ponte - Guimarães',
            'Estrada Nacional 101',
            'Guimarães - Braga',
            'a 6 Km de Guimarães',
            'a 1 Km de Caldas das Taipas',
            '41°28\'53.383"N, 8°20\'7.59"W',
          ],
        },
      ],
    },
  },
}
