export default {
  headerTheme: 'default',
  language: 'pt',
  isMenuOpen: false,
  featuredProject: {
    name: null,
    image: null,
    url: null,
  },
  galleries: [
    {
      id: 'freiria',
      name: 'Quinta da Freiria',
      featured: true,
      images: [
        { src: 'IMG_9350_eiyrxi' },
        { src: 'IMG_9342_vp7dtr' },
        { src: 'IMG_9351_c8grqe' },
        { src: 'IMG_9349_uahuvv' },
        { src: 'IMG_9343_p9lyca' },
        { src: 'IMG_9339_ipqnyy' },
        { src: 'IMG_9336_pdappa', featured: true },
        { src: 'IMG_9345_txhemy' },
        { src: 'IMG_9335_lue0rt' },
        { src: 'IMG_9332_socyls' },
        { src: 'IMG_9329_queiij' },
        { src: 'IMG_9330_etiyfr' },
        { src: 'IMG_9331_kecn9s' },
        { src: 'IMG_9320_npz321' },
        { src: 'IMG_9328_i86tqw' },
        { src: 'IMG_9323_suvxii' },
        { src: 'IMG_9327_fvfrbi' },
        { src: 'IMG_9314_ssnvfx' },
        { src: 'IMG_9325_vjzkhl' },
        { src: 'IMG_9326_mp4kfu', featured: true },
        { src: 'IMG_9322_kamxrb' },
        { src: 'IMG_9312_zrwunx' },
        { src: 'IMG_9302_jczfpo' },
        { src: 'pf_006_if59iv' },
        { src: 'IMG_9309_u7lw0m' },
      ],
    },
    {
      id: 'gardens',
      name: 'Jardins',
      featured: true,
      images: [
        { src: 'pf_528_v7xvbj' },
        { src: 'pf_534_ch93ml' },
        { src: 'pf_115_r0rnyv' },
        { src: 'pf_483_gfsccc' },
        { src: 'pf_444_umf0kt', featured: true },
        { src: 'pf_417_vqiwup' },
        { src: 'pf_450_prkyee' },
        { src: 'pf_424_qfofjv' },
        { src: 'pf_359_mbizrm' },
        { src: 'pf_434_hqjise' },
        { src: 'pf_416_kb3kfo' },
        { src: 'pf_409_j5lp5p' },
        { src: 'pf_312_wdcwa6' },
        { src: 'pf_338_dvk65n' },
        { src: 'pf_334_wxbxwp' },
        { src: 'pf_363_s745zz' },
        { src: 'pf_295_elqqbb', featured: true },
        { src: 'pf_299_d91odr' },
        { src: 'pf_330_pxen2x' },
        { src: 'pf_322_e5i3s8' },
        { src: 'pf_282_vktsda' },
        { src: 'pf_293_exrh0u' },
        { src: 'pf_291_focsbx' },
        { src: 'pf_159_kidyt5' },
        { src: 'pf_106_y8tt5x' },
        { src: 'pf_278_jkdhb1' },
        { src: 'pf_277_n4keb8' },
        { src: 'pf_272_vhniat' },
        { src: 'pf_223_axa7gm' },
        { src: 'pf_181_iokvwg' },
        { src: 'pf_163_kegaod' },
        { src: 'pf_134_endvkr' },
        { src: 'pf_139_km403a' },
        { src: 'pf_137_iydnob' },
        { src: 'DSC_0254_cgkru1' },
        { src: 'DSC_0275_zcpfjb' },
        { src: 'DSC_0243_qpjljo' },
        { src: 'DSC_0230_it7wsx' },
        { src: 'DSC_0162_izlzf2' },
        { src: 'DSC_0205_dbaeaz' },
        { src: 'DSC_0215_zkq7jz' },
        { src: 'DSC_0203_hjxf74' },
        { src: 'DSC_0187_mg5vso' },
        { src: 'DSC_0142_l5wskd' },
        { src: 'DSC_0117_aynikk' },
        { src: 'DSC_0161_npguq4' },
        { src: 'DSC_0139_xd9e5z' },
        { src: 'DSC_0132_b02nwu' },
        { src: 'DSC_0053_lzd3x1' },
        { src: 'DSC_0058_szmzbk' },
        { src: 'DSC_0044_qhemum' },
        { src: 'DSC_0019_z8ijjg' },
        { src: 'DSC_0034_vxbskq', featured: true },
        { src: 'DSC_0006_zxalxx' },
        { src: 'DSC_0013_itr25a' },
        { src: 'DSC_0012_if5ykq' },
        { src: 'DSC_0009_sydakd' },
        { src: 'DSC_0004_dzgosy' },
        { src: 'DSC_0005_esyp8k' },
      ],
    },
    {
      id: 'nurseries',
      name: 'Viveiros',
      images: [
        { src: 'DSC_0198_y5wdqt' },
        { src: 'DSC_0140_bcuuqt' },
        { src: 'DSC_0163_qf0cy2' },
        { src: 'DSC_0086_a9smjd' },
        { src: 'DSC_0141_xvyvcw' },
        { src: 'DSC_0083_m1auec' },
        { src: 'DSC_0153_qezcu7' },
        { src: 'DSC_0127_n6oaau' },
        { src: 'DSC_0134_tfyyoh' },
        { src: 'DSC_0128_bd9wxr' },
        { src: 'DSC_0042_mbrt8j' },
        { src: 'DSC_0129_ygyqcl' },
        { src: 'DSC_0119_kuwzim' },
        { src: 'DSC_0101_wmfinc' },
        { src: 'DSC_0054_gro90q' },
        { src: 'DSC_0084_dp4yxf' },
        { src: 'DSC_0075_vhf1wv' },
        { src: 'DSC_0080_znq8nn' },
        { src: 'DSC_0072_up8szb' },
      ],
    },
  ],
}
