<template>
  <div class="gallery">
    <h1
      class="gallery__title"
      v-html="$t(`gallery.${getGallery($route.params.id).id}`)"
    ></h1>
    <router-link
      v-for="(image, index) in getGallery($route.params.id).images"
      :to="`/gallery/${$route.params.id}/${image.src}`"
      v-bind:key="index"
      class="gallery__link"
    >
      <img
        class="gallery__thumbnail"
        :src="$store.getters.cloudinary(image.src, 300, 300, 2)"
        :srcset="`${$store.getters.cloudinary(
          image.src,
          300,
          300,
          2
        )} 1x, ${$store.getters.cloudinary(image.src, 300, 300, 2)} 2x`"
      />
    </router-link>
    <p class="footer">COPYRIGHT 2018 © PLANTAS DA FONTE. ALL RIGHTS RESERVED</p>
  </div>
</template>

<style lang="sass" scoped>

$imageSize: 300px
$gutter: 1em

.gallery
  display: flex
  flex-flow: row wrap
  max-width: calc(#{$imageSize} + #{$gutter})
  padding-top: 50px
  margin: 0 auto

.gallery__link
  margin: 0 $gutter/2 $gutter
  overflow: hidden

  &:hover .gallery__thumbnail
    transform: scale(1.1)

.gallery__thumbnail
  transform: scale(1)
  transition: all 3s

.gallery__title
  flex: 1 100%
  margin: 1em 0.25em 0.5em
  order: 0
  font-size: 2em
  font-weight: 500
  text-align: left

@media all and (min-width: 650px)
  .gallery
    max-width: calc((#{$imageSize} + #{$gutter}) * 2)

@media all and (min-width: 970px)
  .gallery
    max-width: calc((#{$imageSize} + #{$gutter}) * 3)

@media all and (min-width: 1300px)
  .gallery
    max-width: calc((#{$imageSize} + #{$gutter}) * 4)

@media all and (min-width: 1640px)
  .gallery
    max-width: calc((#{$imageSize} + #{$gutter}) * 5)

.footer
  width: 100%
  padding: 1em
  font-size: 0.75em
</style>

<script>
import find from 'lodash/fp/find'

export default {
  methods: {
    getGallery(id) {
      return find({ id })(this.$store.state.galleries)
    },
    title(id) {
      return find({ id })(this.$store.state.galleries).id
    },
  },
}
</script>
