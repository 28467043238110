<template>
  <header>
    <transition name="slide">
      <div
        v-if="!$store.state.isMenuOpen"
        :class="`header header--${$store.getters.theme($route.name)}`"
      >
        <button class="header-menu" @click="onMenuClick">
          <span class="icon-menu"></span>
        </button>
        <div class="header-logo">
          <a href="http://plantasdafonte.pt">
            <PlantasDaFonteLogo class="header-svg" />
          </a>
        </div>
      </div>
    </transition>
    <MenuItem />
  </header>
</template>

<style lang="sass" scoped>
@import '~@/_variables.sass'

.slide-enter-active
  transform: translateY(-$header-height)
  animation: .3s ease-in-out .3s slidein

.slide-leave-active
  animation: .3s ease-in-out reverse slidein

@keyframes slidein
  0%
    transform: translateY(-$header-height)
  100%
    transform: translateY(0)

.header
  position: fixed
  top: 0
  display: flex
  flex-flow: row nowrap
  justify-content: space-between
  align-items: center
  width: 100vw
  height: $header-height
  padding: 0 1em
  z-index: 3

  &--default
    background-color: transparentize($color-white, 0.1)

    .icon-menu
      color: $color-black

    .header-svg
      fill: $color-black

  &--dark
    background-color: transparentize($color-black, 0.1)

    .icon-menu
      color: $color-white

    .header-svg
      fill: $color-white

  &--green
    background-color: transparentize($color-green, 0.1)

    .icon-menu
      color: $color-black

    .header-svg
      fill: $color-black

.header-menu
  padding: 0
  border: none
  color: $color-black
  background-color: transparent
  cursor: pointer
  &:focus
    outline: none

.icon-menu
    font-size: 1.6em

.header-logo
  padding-top: 0.1em
</style>

<script>
import MenuItem from './MenuItem'
import PlantasDaFonteLogo from './PlantasDaFonteLogo'

export default {
  methods: {
    onMenuClick() {
      this.$store.commit('menu', true)
    },
  },
  components: {
    MenuItem,
    PlantasDaFonteLogo,
  },
}
</script>
