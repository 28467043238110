<template>
  <div class="about">
    <section class="about-content">
      <h2 class="about__h2">{{ $t('about.company.title') }}</h2>
      <article class="about__information">
        <p class="about__p">{{ $t('about.company.text.p1') }}</p>
        <p class="about__p">{{ $t('about.company.text.p2') }}</p>
        <p class="about__p">{{ $t('about.company.text.p3') }}</p>
      </article>
      <hr class="about__hr" />
      <h2 class="about__h2">{{ $t('about.services.title') }}</h2>
      <article class="about__information about__information--col">
        <h3 class="about__h3 about__p">
          {{ $t('about.services.general.title') }}
        </h3>
        <ul>
          <li
            class="about__p"
            v-for="(service, index) in $tm('about.services.general.list')"
            :key="index"
          >
            {{ service }}
          </li>
        </ul>
        <h3 class="about__h3 about__p">
          {{ $t('about.services.events.title') }}
        </h3>
        <ul>
          <li
            class="about__p"
            v-for="(service, index) in $tm('about.services.events.list')"
            :key="index"
          >
            {{ service }}
          </li>
        </ul>
        <h3 class="about__h3 about__p">
          {{ $t('about.services.maintenance.title') }}
        </h3>
        <ul>
          <li
            class="about__p"
            v-for="(service, index) in $tm('about.services.maintenance.list')"
            :key="index"
          >
            {{ service }}
          </li>
        </ul>
      </article>
    </section>
  </div>
</template>

<style lang="sass" scoped>
@import '~@/_variables.sass'

.about
  padding: calc(2em + #{$header-height}) 2em 1em
  text-align: left
  color: $color-black
  background-color: $color-green
  min-height: 100vh
  line-height: 1.2em

.about-content
  max-width: 800px
  margin: 0 auto

.about__h2, .about__h3
  margin-bottom: 1em
  font-weight: bold

.about__h2
  font-size: 2em

.about__information

  &--col
    columns: 2 300px

  & p
    margin-bottom: 1em

ul
  margin-bottom: 2em
  list-style: none

  & > li:before
    content: "- "
    text-indent: -5px

.about__hr
  margin: 5em 0
  border: none
  border-bottom: 1px solid transparentize($color-black, 0.3)

.about__p
  font-size: $font-size
  line-height: $line-height
</style>
