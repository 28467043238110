<template>
  <transition name="menu">
    <nav v-if="$store.state.isMenuOpen" class="menu" name="menu">
      <button
        class="menu__link menu__link--close"
        @click="onCloseMenuClick"
        key="menu-item-1"
      >
        <span class="icon-close"></span>
      </button>
      <router-link
        class="menu__link menu__link--underline"
        @click="onCloseMenuClick"
        v-for="item in $options.menuItems"
        :key="item.key"
        :to="item.link"
      >
        {{ $t(item.translation) }}
      </router-link>
      <select class="menu__select" v-model="language" key="menu-item-8">
        <option value="en">English</option>
        <option value="fr">Français</option>
        <option value="es">Castellano</option>
        <option value="pt">Portugues</option>
      </select>
      <button
        class="menu__link menu__link--close"
        @click="onCloseMenuClick"
        key="menu-item-1"
      >
        <span class="icon-close"></span>
      </button>
      <a
        class="menu__link menu__link--underline menu__link--small"
        href="https://livroreclamacoes.pt/"
        target="_blank"
      >
        Livro de Reclamações
      </a>
    </nav>
  </transition>
</template>

<style lang="sass" scoped>
@import '~@/_variables.sass'

.menu-enter-active
  opacity: 0
  animation: .5s ease-in-out 0.2s slidein

.menu-leave-active
  animation: .5s ease-in-out reverse slidein

@keyframes slidein
  0%
    opacity: 0
  100%
    opacity: 1

.menu
  position: fixed
  overflow-y: auto
  display: flex
  flex-flow: column nowrap
  align-items: center
  width: 100vw
  height: 100vh
  top: 0
  left: 0
  padding: 4em 1em 0
  background-color: transparentize($color-white, 0.1)
  z-index: 3

.menu__link
  position: relative
  font-weight: bold
  font-size: 2em
  line-height: 1.5em
  padding-top: 1em 0
  color: $color-black
  text-decoration: none

  &--underline:before
    content: ''
    position: absolute
    width: 100%
    height: 1px
    bottom: 0
    left: 0
    background-color: $color-black
    visibility: hidden
    transform: scaleX(0)
    transition: all 0.3s ease-in-out 0s

  &--underline:hover:before
    visibility: visible
    transform: scaleX(1)

  &--close
    position: fixed
    top: 0
    left: 0.5em
    padding: 0
    border: none
    background-color: transparent
    cursor: pointer
    align-self: flex-start
    &:focus
      outline: none

  &--small
    margin-top: 1em
    font-size: 1em
    font-weight: normal

.menu__select
  margin: 1em
  border: 1px solid $color-black
  border-radius: 3px
  background-color: transparent
  padding: 0.7em 2em 0.7em 0.7em
  font-size: 1em
  appearance: none
  background: url('data:image/svg+xml;utf8,<svg width="41px" height="24px" viewBox="0 0 41 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g transform="translate(-4.000000, -18.000000)" fill="%23000" fill-rule="nonzero"><polygon transform="translate(24.529437, 21.500000) rotate(-225.000000) translate(-24.529437, -21.500000) " points="39.0294373 7 39.0294373 36 34.0294373 36 34.0294373 13.1489995 34.1534213 11.876016 32.8389159 12 10.0294373 12 10.0294373 7"></polygon></g></g></svg>') 90% / 10% no-repeat transparent

  &:focus
    appearance: none
    outline: none

.icon-close:before
  margin: 0

.menu__link.router-link-exact-active
  cursor: default
  opacity: 0.5

.TODO
  content: ""
  position: absolute
  bottom: 0
  left: 0
  height: 2px
  width: 100%
  background-color: $color-black
</style>

<script>
export default {
  menuItems: [
    { key: 'itm1', translation: 'menu.home', link: '/' },
    {
      key: 'itm2',
      translation: 'menu.quinta_freiria',
      link: '/gallery/freiria',
    },
    { key: 'itm3', translation: 'menu.gardens', link: '/gallery/gardens' },
    { key: 'itm4', translation: 'menu.nurseries', link: '/gallery/nurseries' },
    { key: 'itm5', translation: 'menu.contact', link: '/contact' },
    { key: 'itm6', translation: 'menu.about', link: '/about' },
  ],
  computed: {
    language: {
      get() {
        return this.$i18n.locale
      },
      set(value) {
        this.$i18n.locale = value
        this.$store.commit('language', value)
        localStorage.setItem('language', value)
      },
    },
  },
  methods: {
    onCloseMenuClick() {
      this.$store.commit('menu', false)
    },
  },
}
</script>
