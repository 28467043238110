<template>
  <section class="contact">
    <div class="contact__map">
      <div id="map" class="map"></div>
    </div>
    <div class="contact__footer">
      <article class="contact__information">
        <div
          class="contact__details"
          v-for="(details, index) in $tm('contact.details')"
          :key="index"
        >
          <h3 class="contact__category">{{ details.title }}</h3>
          <p
            class="contact__text"
            v-for="(text, index) in details.texts"
            :key="index"
          >
            {{ text }}
          </p>
        </div>
      </article>
    </div>
  </section>
</template>

<style lang="sass" scoped>
@import '~@/_variables.sass'

#map
  height: 60vh
  z-index: 1

.contact
  display: flex
  flex-flow: column
  min-height: 100vh
  text-align: left
  color: $color-white
  background-color: $color-black

.contact__map
  padding-top: $header-height
  flex: 0 1 auto

.contact__footer
  flex: 1 1 auto
  padding: 2em 1em 0
  margin: 0 auto

.contact__details
  margin-bottom: 3em
  &:nth-last-child(2)
    display: inline-block

.contact__information
  columns: 2 300px

.contact__category
  margin-bottom: 1em
  font-weight: bold

.contact__text
  margin-bottom: 0.2em
</style>

<script>
// global google
let map

const initMap = async () => {
  const pf = { lat: 41.481, lng: -8.337 }
  const { Map } = await global.google.maps.importLibrary('maps')

  map = new Map(document.getElementById('map'), {
    center: pf,
    zoom: 11,
  })

  const request = {
    location: map.getCenter(),
    radius: '500',
    query: 'PLANTAS DA FONTE LDA',
  }

  // Checks that the PlacesServiceStatus is OK, and adds a marker
  // using the place ID and location from the PlacesService.
  const callback = (results, status) => {
    if (status === global.google.maps.places.PlacesServiceStatus.OK) {
      new global.google.maps.Marker({
        map: map,
        place: {
          placeId: results[0].place_id,
          location: results[0].geometry.location,
        },
      })
    }
  }
  const service = new global.google.maps.places.PlacesService(map)
  service.textSearch(request, callback)
}

export default {
  mounted: function () {
    initMap()
  },
}
</script>
