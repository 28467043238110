<template>
  <div class="app">
    <HeaderItem />
    <router-view />
  </div>
</template>

<style lang="sass">
@import '~@/_reset.sass'
@import '~@/_variables.sass'

body
  text-size-adjust: 100%
  color: $color-black
  background-color: $color-white

@font-face
  font-family: 'icons'
  src: url('./assets/icons.eot?77397551')
  src: url('./assets/icons.eot?77397551#iefix') format('embedded-opentype'),url('./assets/icons.woff2?77397551') format('woff2'),url('./assets/icons.woff?77397551') format('woff'),url('./assets/icons.ttf?77397551') format('truetype'),url('./assets/icons.svg?77397551#icons') format('svg')
  font-weight: normal
  font-style: normal

[class^="icon-"]:before, [class*=" icon-"]:before
  font-family: "icons"
  font-style: normal
  font-weight: normal
  speak: none

  display: inline-block
  text-decoration: inherit
  width: 1em
  margin-right: .2em
  text-align: center
  //* opacity: .8 */

  //* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal
  text-transform: none

  //* fix buttons height, for twitter bootstrap */
  line-height: 1em

  //* Animation center compensation - margins should be symmetric */
  //* remove if not needed */
  margin-left: .2em

  //* you can be more comfortable with increased icons size */
  //* font-size: 120% */

  //* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

  //* Uncomment for 3D effect */
  //* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3) */

.icon-menu:before
  content: '\e806'
.icon-close:before
  content: '\e80B'
.icon-next:before
  content: '\e80C'
.icon-prev:before
  content: '\e80D'

.app
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
  color: $color-black
</style>

<script>
import HeaderItem from './components/HeaderItem'

export default {
  components: {
    HeaderItem,
  },
}
</script>
