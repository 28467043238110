<template>
  <div class="home">
    <h2 class="home__title">
      {{ $t('home.title_p1') }}<br class="home__br" />{{ $t('home.title_p2') }}
    </h2>
    <router-link class="home__link" :to="$store.state.featuredProject.url">
      <h2>{{ $store.state.featuredProject.name }}</h2>
    </router-link>
    <img
      class="home__image"
      :src="
        $store.getters.cloudinary(
          $store.state.featuredProject.src,
          1280,
          720,
          1
        )
      "
      :srcset="`${$store.getters.cloudinary(
        $store.state.featuredProject.src,
        1280,
        720,
        1
      )} 1280w, ${$store.getters.cloudinary(
        $store.state.featuredProject.src,
        1024,
        768,
        1
      )} 1024w, ${$store.getters.cloudinary(
        $store.state.featuredProject.src,
        800,
        600,
        1
      )} 800w`"
      sizes="(max-width: 800px) 800px,
             (max-width: 1024px) 1024px,
             1280px"
      :alt="$store.state.featuredProject.name"
    />
  </div>
</template>

<style lang="sass" scoped>
@import '~@/_variables.sass'

.home
  position: relative
  height: 100vh
  overflow: hidden
  background-color: $color-black

.home__title
  position: absolute
  z-index: 2
  top: 100px
  left: 0.5em
  text-align: left
  font-size: 2.5em
  font-weight: 300
  line-height: 1.3em
  color: $color-white
  text-shadow: 0 1px $color-black

.home__br
  content: ""
  display: block
  margin-bottom: 0.5em

.home__image
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  object-fit: cover
  object-position: 50% 50%
  width: 100vw
  height: 100vh
  transition: transform 3s cubic-bezier(0.645, 0.045, 0.355, 1)

.home__link
  position: absolute
  width: 80vw
  z-index: 1
  right: 10vw
  bottom: 15vh
  font-size: 1.5em
  padding: 0.5em 2em
  border: 1px solid $color-white
  color: $color-white
  text-decoration: none
  font-weight: bold
  text-shadow: 0 1px 0 transparentize($color-black, 0.5)
  box-shadow: $color-white 0 0 0 0 inset
  transition: all .3s cubic-bezier(0.645, 0.045, 0.355, 1)

  &:hover
    color: $color-black
    box-shadow: $color-white 0 0 0 2em inset
    color: $color-black
    text-shadow: none

    ~ .home__image
      transform: scale(1.05)

@media all and (min-width: 650px)
  .home__link
    width: auto

  .home__title
    left: 10%
    font-size: 3em

  .home__br
    margin-bottom: 0

@media all and (min-width: 970px)
  .home__link
    width: auto
</style>

<script>
export default {
  beforeMount() {
    this.$store.commit('featuredProject', this.$store.getters.featuredProject)
  },
}
</script>
