<script>
import find from 'lodash/fp/find'
import findIndex from 'lodash/fp/findIndex'
import map from 'lodash/fp/map'

import PhotoSwipe from 'photoswipe'
import 'photoswipe/style.css'
import '../assets/photoswipe-theme.css'

export default {
  components: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      const img = { src: this.$route.params.image }
      const index = findIndex(img)(this.images)
      this.pswp.goTo(index)
    },
  },
  mounted() {
    if (this.pswp) {
      return
    }

    const { images } = find({ id: this.$route.params.gallery })(
      this.$store.state.galleries
    )
    this.images = images
    const { cloudinary } = this.$store.getters
    const dataSource = map((img) => {
      return {
        srcset: `${cloudinary(img.src, 1600, 900, 2)} 1600w, ${cloudinary(
          img.src,
          1200,
          675,
          2
        )} 1200w, ${cloudinary(img.src, 600, 338, 2)} 600w`,
        src: this.$store.getters.cloudinary(img.src, 1600, 900, 2),
        width: 1600,
        height: 900,
        pid: img.src,
      }
    })(images)

    // define options (if needed)
    const img = { src: this.$route.params.image }
    const index = findIndex(img)(images)

    this.pswp = new PhotoSwipe({
      index,
      dataSource,
      counter: false,
      zoom: false,
      bgOpacity: 0,
      close: false,
    })
    this.pswp.on('change', () => {
      const { pid } = this.pswp.currSlide.content.data
      this.$router.push(`/gallery/${this.$route.params.gallery}/${pid}`)
    })
    this.pswp.on('close', () => {
      const { gallery } = this.$route.params
      if (gallery) {
        this.$router.push(`/gallery/${gallery}`)
      }
    })
    this.pswp.init()
  },
  unmounted() {
    if (this.pswp) {
      this.pswp.destroy()
      this.pswp = null
    }
  },
}
</script>
